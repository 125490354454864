"use strict";

/**
 * @ngdoc function
 * @name informaApp.controller:FaqCtrl
 * @description FAQ and methodology section
 * # FaqCtrl
 * Controller of the informaApp
 */

angular.module("informaApp")
    .controller("FaqCtrl", ["$scope", "$location", "$http", "ConstantsSvc", "FaqSvc", "OmnitureSvc",
        function ($scope, $location, $http, ConstantsSvc, FaqSvc, OmnitureSvc) {
            OmnitureSvc.trackFAQ();

            var url = ConstantsSvc.API.URL + 'faq/';

            $scope.getDownloadUrl = function(id) {
                return url + 'download/?id=' + id
            };

            $scope.downloadMethodology = function () {
                FaqSvc.downloadMethodology();
            };

            $scope.downloadAttachment = function (model) {
                FaqSvc.downloadAttachment(model.id);
            };

            FaqSvc.getAllQuestion()
                .then(function(res) { $scope.faq = res.data })
                .catch(function(err) { console.log(err) });
        }]);